<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="xl"
      color="secondary"   
      addContentClasses="modal-max-width"
    >
      <List ref="list"  
        id="printArea"  
        :fields=fields
        :items.sync=statsDetailList
        :loading.sync=loading
        sorter   
        :defaultSort=defaultSort
        showExcelBtn
        itemsPerPageSelect
        :itemsPerPage=itemPerPage
        showPrintBtn
        :excel=excel
        :isModal=isModal
      >    
      <template #showSearchEtc>        
        <date-picker v-model="searchDate" range style="width: 210px;" @change="changeDate()" class="ml-1" :clearable="false"></date-picker>
        <CSelect
            class="ml-1"
            :options=appPackageNmList
            :value.sync="selectApp"
            @change=changeApp
        />  
        <CSelect
            :options=comboboxData
            :value.sync=selectStatsResultType
            class="ml-1"
            @change=changeStatsResultType
        />
      </template>
      <template #clearBtn>
        <CButton v-if="showClearBtn"
            @click="clear"
        >
          <CIcon
              :content="$options.icons.cilReload"
              style="color:#ffffff"
          />
        </CButton>
      </template>
      <template #showExcelBtn>
        <CButton
            square
            size="sm"
            v-c-tooltip="'엑셀 다운로드'"
            @click="excelExport()"
            class="btn btn-primary btn-lft-margin"
            >
            <!-- <CIcon
                :content="$options.icons.cilDescription"
            /> -->
                                    
            <img
                src="@/assets/icons/IconExcelDown.svg"
                width=19
                height=23
            />
        </CButton>
      </template>

      <template #no="{index}">
          <td>
            {{totalCnt - ((pageNum -1) * itemPerPage +index)}}
          </td>
      </template>
      </List>       
      <page-link ref="page"
      :pageLinkCount=pagePerLink
      :listRowCount=itemPerPage
      />
      <template #header>
        <h6 class="modal-title">통계상세현황</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
      </template>
      <template #footer-wrapper>
        <!-- <CButton color="secondary" @click="$emit('close-modal')">닫기</CButton> -->
        <span></span>
      </template>
    </CModal>
  </div>

</template>

<script>
import List from '@/views/common/List'
import PageLink from '@/views/common/PageLink'
import { cilReload } from '@coreui/icons'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'StatsTamperModal',
  icons: { cilReload },
  components: {
    List,
    PageLink,
    DatePicker
  },
  props: {
    caption: {
      default:  '상세 현황',      
    },
    statsResultType: {
      String, // 0: 총접속, 1: 정상접속, 2: 이상탐지, 3: 검증실패
      default:'0'
    },
    startDate:String,
    endDate:String,
    appKey:String,
    packageNm:String,
    appVersion:String,
    osType:String,
    storeYn:String,
  },
  data () {
    return {
        isModalOpen:true,
        searchText:'',
        //TODO comboboxData만 검색조건에 있을경우 List.vue에서 인풋박스 안나오게 수정 필요
        comboboxData:[{label: '총 접속', value: '0'},{label: '정상접속', value:'1'}, {label: '이상탐지', value:'2'}, {label: '검증실패', value:'3'}],
        fields:[
            { key: 'no', label:'', _style: { width: '1%'}, mapping:''},
            { key: 'reqDate', label:'접속일', _style: { width: '8%'} },
            { key: 'appNm', label:'앱 이름', _style: { width: '20%'} },
            { key: 'packageNm', label:'패키지명', _style: { width: '20%'} },
            { key: 'osType', label: 'OS', sorter: false, _style: { width: '20%'}, iconYn: true },
            { key: 'appVersion', label: '버전', _style: {width: '8%'}, codeYn: true},
            { key: 'validationResult', label: '검증', _style: {width: '8%'}, badgeYn: true, codeYn: true },
            { key: 'appTamperYn', label: 'App', _style: {width: '8%'}, badgeYn: true, codeYn: true,  code: 'tamperYn' },
            { key: 'osTamperYn', label: 'OS', _style: {width: '8%'}, badgeYn: true, codeYn: true,  code: 'tamperYn' },
            { key: 'debugYn', label: '디버깅', _style: {width: '8%'}, badgeYn: true, codeYn: true,  code: 'tamperYn' }
        ],
        pagePerLink: 5,
        defaultSort: '-reqDate',
        pageNum: 1,
        itemPerPage:10,
        loading:false, 
        searchDate:[new Date(this.startDate),new Date(this.endDate)],
        excel: {
          excelType : '6',  // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록, 6: 대시보드 상세현황)
          excelFileNm : "통계상세현황"
        },
        selectAppList: [{value:"", label:"앱 전체"}],
        showClearBtn:false,
        selectApp:this.appKey,
        selectPackageNm:this.packageNm,
        selectAppVersion:this.appVersion,
        selectOsType:this.osType,
        selectStoreYn:this.storeYn,
        selectStatsResultType:this.statsResultType,
        isModal:true
    }
  },
  computed: {
    statsDetailList(){ 
      return this.$store.state.statistics.statsDetailList || []
    },       
    totalCnt(){
      return this.$store.state.statistics.statsDetailTotalCnt
    },
    appAllList(){
      return this.$store.state.appInfo.appAllList || []
    },
    appPackageNmList(){
      if(this.selectAppList.length == 1 && this.appAllList.length > 0){
        for(var i in this.appAllList){
          this.selectAppList.push({
            value : this.appAllList[i]['appKey'],
            label: this.appAllList[i]['appNm']+" - v"+this.$store.state.appInfo.appAllList[i]['appVersion']
          })
        }
      }
      return this.selectAppList
    }
  },
  mounted(){
    this.searchList()
  },
  created(){    
  },
  methods: {
    // 리스트 조회 
    async searchList(){
      let payload = this.$refs.list.getParams()
      this.loading=true 
      try {
        await this.$store.dispatch('statistics/getStatsDetailList', this.makePayload(payload))
        this.$refs.page.reloadPagination(this.totalCnt)
        this.loading=false
      } catch(error){
        this.$response(error)
        this.loading=false
      }        
    },
    changeStatsResultType(){
      this.searchList()
      this.showClearBtn=true
    },
    changeApp(){
      if(this.selectApp){
        for(var i in this.appAllList){
          if(this.appAllList[i]['appKey'] == this.selectApp){
            this.selectPackageNm = this.appAllList[i]['packageNm']
            this.selectAppVersion = this.appAllList[i]['appVersion']
            this.selectOsType = this.appAllList[i]['osType']
            this.selectStoreYn = this.appAllList[i]['storeYn']
            break
          }
        }
      }
      else{        
          this.selectPackageNm = ''
          this.selectAppVersion = ''
          this.selectOsType = ''
          this.selectStoreYn = ''
      }
      this.searchList()
      this.showClearBtn=true
    },
    clear(){     
      this.searchDate=[new Date(this.startDate),new Date(this.endDate)]
      this.selectStatsResultType=this.statsResultType
      this.selectApp=this.appKey
      this.selectPackageNm = this.packageNm
      this.selectAppVersion = this.appVersion
      this.selectOsType = this.osType
      this.selectStoreYn = this.storeYn
      this.searchList() 
      this.showClearBtn=false
    },
    changeDate(){
      this.searchList()
      this.showClearBtn=true
    },    
    // 엑셀 다운로드 재정의
    excelExport(){         
        if(this.statsDetailList && this.statsDetailList.length>0){
          
            let payload = this.$refs.list.getExcelParam()            
            this.$refs.list.$excelDownload(this.makePayload(payload))
        }   
        else alert("데이터가 없습니다.")
    },
    makePayload(payload){
      // 입력 파라미터 추가
      payload["startDate"]=this.$date(this.searchDate[0]).format('YYYY-MM-DD')
      payload["endDate"]=this.$date(this.searchDate[1]).format('YYYY-MM-DD')
      payload['statsResultType']=this.selectStatsResultType
      payload['packageNm']=this.selectPackageNm
      payload['appVersion']=this.selectAppVersion
      payload['osType']=this.selectOsType
      payload['storeYn']=this.selectStoreYn
      return payload
    }
  }
}
</script>
