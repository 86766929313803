<template>
  <div>
      <CRow>
          <CCol sm="12" class="d-none d-md-block">
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-secondary"
                v-for="(value, key) in ['Day', 'Month']"
                :key="key"
                class="mx-0"
                :pressed="value === selectedDate ? true : false"
                @click="selectedDate = value"
              >{{value}}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      <CChartLine
        style="height:300px;margin-top:40px;"
        :datasets="defaultDatasets"
        :options="defaultOptions"
        :labels="selectedDate == 'Month' ? monthLabels : dayLabels"
      />
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

export default {
  name: 'MainChartExample',
  components: {
    CChartLine
  },
  computed: {
    startDate(){
      return this.$store.state.statistics.statsStartDate || ''
    },
    endDate(){
      return this.$store.state.statistics.statsEndDate || ''
    },
    statsDailyByDateCntList(){
      return this.$store.state.statistics.statsDailyByDateCntList || []
    },
    statsMonthlyByDateCntList(){
      return this.$store.state.statistics.statsMonthlyByDateCntList || []
    },
    dayLabels(){
      var tempDates = []
      var fromDate = this.startDate
      var toDate = this.$date(this.endDate).add(-1, 'day').format('YYYY-MM-DD')
      if(fromDate < toDate){
        while(fromDate <= toDate){
          var dateLable = this.$date(fromDate).format('MM-DD')
          tempDates.push(dateLable)
          fromDate = this.$date(fromDate).add(1, 'day').format('YYYY-MM-DD')
        }
      }
      return tempDates
    },
    monthLabels(){
      var tempDates = []
      for(var i=0; i < 12; i++){
        tempDates.push(i+1)
      }
      return tempDates
    },
    defaultDatasets () {
     
      const appTamperAndroid = '#DF0101'
      const osTamperAndroid = '#0174DF'
      const debugAndroid = '#088A29'
      const appTamerIos ='#FE642E'
      const osTamperIos ='#01A9DB'
      const debugIos='#5FB404'

      const andAppData = [] 
      const andOsData = []
      const andDebugData = []
      const iosAppData = []
      const iosOsData = []
      const iosDebugData = []

      //day, month
      if(this.selectedDate == 'Month'){
        for(var i=0; i<12; i++){
          andAppData.push(0)
          andOsData.push(0)
          andDebugData.push(0)
          iosAppData.push(0)
          iosOsData.push(0)
          iosDebugData.push(0)
        }

        this.setArrDataByMonth(andAppData, this.statsMonthlyByDateCntList, 'andAppData');
        this.setArrDataByMonth(andOsData, this.statsMonthlyByDateCntList, 'andOsData');
        this.setArrDataByMonth(andDebugData, this.statsMonthlyByDateCntList, 'andDebugData');
        this.setArrDataByMonth(iosAppData, this.statsMonthlyByDateCntList, 'iosAppData');
        this.setArrDataByMonth(iosOsData, this.statsMonthlyByDateCntList, 'iosOsData');
        this.setArrDataByMonth(iosDebugData, this.statsMonthlyByDateCntList, 'iosDebugData');
        
      }else{
        for(var i in this.statsDailyByDateCntList){
          andAppData.push(this.statsDailyByDateCntList[i]['andAppTamperDetectCnt'])
          andOsData.push(this.statsDailyByDateCntList[i]['andOsTamperDetectCnt'])
          andDebugData.push(this.statsDailyByDateCntList[i]['andDebugDetectCnt'])
          iosAppData.push(this.statsDailyByDateCntList[i]['iosAppTamperDetectCnt'])
          iosOsData.push(this.statsDailyByDateCntList[i]['iosOsTamperDetectCnt'])
          iosDebugData.push(this.statsDailyByDateCntList[i]['iosDebugDetectCnt'])
        }
      }

      return [
        {
          //TODO 라벨에 이미지 추가
          label: '앱위변조(Android)',
          backgroundColor: hexToRgba(appTamperAndroid, 10),
          borderColor: appTamperAndroid,
          pointHoverBackgroundColor: appTamperAndroid,
          borderWidth: 2,
          data: andAppData
        },
        {
          label: 'OS위변조(Android)',
          backgroundColor: 'transparent',
          borderColor: osTamperAndroid,
          pointHoverBackgroundColor: osTamperAndroid,
          borderWidth: 2,
          data: andOsData
        },
        {
          label: '디버깅(Android)',
          backgroundColor: 'transparent',
          borderColor: debugAndroid,
          pointHoverBackgroundColor: debugAndroid,
          borderWidth: 2,
          //borderDash: [8, 5],
          data: andDebugData
        },{
          label: '앱위변조(iOS)',
          backgroundColor: 'transparent',
          borderColor: appTamerIos,
          pointHoverBackgroundColor: appTamerIos,
          borderWidth: 2,
          data: iosAppData
        },
        {
          label: 'OS위변조(iOS)',
          backgroundColor: 'transparent',
          borderColor: osTamperIos,
          pointHoverBackgroundColor: osTamperIos,
          borderWidth: 2,
          data: iosOsData
        },
        {
          label: '디버깅(iOS)',
          backgroundColor: 'transparent',
          borderColor: debugIos,
          pointHoverBackgroundColor: debugIos,
          borderWidth: 2,
          //borderDash: [8, 5],
          data: iosDebugData
        }
      ]
    },
    defaultOptions () {
      return {
        maintainAspectRatio: false,
        color: '#0A6EFF',
        legend: {
          display: true,
          labels: {
            fontStyle: 'Bold',
            fontColor: '#a2a2a2',
            fontSize: 14,
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            ticks:{
               fontColor: '#a2a2a2',
               fontSize: 13,
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(10 / 5),
              //max: 10,
              fontColor: '#a2a2a2',
              fontSize: 13,
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  },
  data(){
    return {
      selectedDate: 'Day',
    }
  },
  mounted(){
  },
  methods:{
    setArrDataByMonth(osTamperData, statsData, type){
      for(var i in osTamperData){
        for(var j in statsData){
          if(i == statsData[j]['regYearMonth'].toString().substr(4)-1){ //month
            switch(type){
              case 'andAppData':
                osTamperData[i] = statsData[j]['andAppTamperDetectCnt']
                break;
              case 'andOsData':
                osTamperData[i] = statsData[j]['andOsTamperDetectCnt']
                break;
              case 'andDebugData':
                osTamperData[i] = statsData[j]['andDebugDetectCnt']
                break;
              case 'iosAppData':
                osTamperData[i] = statsData[j]['iosAppTamperDetectCnt']
                break;
              case 'iosOsData':
                osTamperData[i] = statsData[j]['iosOsTamperDetectCnt']
                break;
              case 'iosDebugData':
                osTamperData[i] = statsData[j]['iosDebugDetectCnt']
                break;
              default:
                osTamperData[i] = 0
                break;
            }
          }
        }
      }
    }
  }
}
</script>
