<template>
  <div>
  <CCard id ="statsPrintArea">
    <!-- 기간, 앱 검색 -->
    <CCardHeader>
      <CRow>
       <CCol sm="7" style="margin-bottom:-20px">
         <CRow>    
             <span style="margin-top:6px;margin-left:10px;">기간 내 조회결과: </span>
            <date-picker ref="datePicker" v-model="searchDate" range style="width: 210px;margin-left:6px;" class="ml-1" @change=changeDate :clearable="false"></date-picker>
            <CSelect
              class="ml-1"
              style="width: 210px;"
              :options=appPackageNmList
              :value.sync="selectedApp"
              @change=changeApp
            />
            <CButton v-if="clearBtn" @click="clear" class="form-inline-bottom-margin ml-1" >
                <CIcon
                    :content="$options.icons.cilReload"
                    style="color:#ffffff"
                />
            </CButton>
         </CRow>
       </CCol>
       <CCol sm="5">
         <CButton
              type="submit"
              size="sm"
              variant="ghost"
              class="float-right btn-primary btn-lft-margin ml-2"
              @click="$print('statsPrintArea')"
          >
              <CIcon name="cil-print"/>
          </CButton>
          <CSwitch
              color="danger"
              size="lg"
              class="float-right ml-1"
              :checked.sync="isRefresh"
              labelOn="ON" 
              labelOff="OFF"
              />
          <span class="float-right mt-1 ">자동 새로고침(1분) : </span>
       </CCol>
      </CRow>
    </CCardHeader>
    <!-- 기간, 앱 검색 -->
    <CCardBody>
      <!-- 카운트 통계 -->
      <StatsTamperCount :key="componentKey"/>
      <!-- 카운트 통계 -->
      <CCardGroup>
        <!-- 꺾은선 그래프 카드 전체 -->
        <CCard style="flex-grow: 2">
          <CCardBody>
             <!-- 꺾은선 그래프 -->
            <StatsTamperLineChart style="height:100%;" :key="componentKey"/>
             <!-- 꺾은선 그래프 -->
          </CCardBody>
        </CCard>
        <!-- 꺾은선 그래프 카드 전체 -->
        <!-- 도넛그래프 전체 -->
        <CCard>
            <CCardBody>
               <StatsTamperDoughnutChart style="height:100%;" :key="componentKey"/>
            </CCardBody>
        </CCard>
        <!-- 도넛그래프 전체 -->
        </CCardGroup>
      </CCardBody>
      <!-- OS별 총 건수 -->
      <CCardFooter>
        <CRow class="text-center">
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
                앱위변조(<img src="@/assets/icons/IconAndroid.svg" width=19 height=23/>)
            </div>
            <strong>{{statsTotalByOsDateCnt['andAppTamperDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" color="success" :value="statsTotalByOsDateCnt['andAppTamperDetectCnt']" />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
                OS위변조(<img src="@/assets/icons/IconAndroid.svg" width=19 height=23 />)
            </div>
            <strong>{{statsTotalByOsDateCnt['andOsTamperDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" color="info" :value="statsTotalByOsDateCnt['andOsTamperDetectCnt']" />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
              디버깅(<img src="@/assets/icons/IconAndroid.svg" width=19 height=23 />)
            </div>
            <strong>{{statsTotalByOsDateCnt['andDebugDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" color="warning" :value="statsTotalByOsDateCnt['andDebugDetectCnt']" />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
              앱위변조(<img src="@/assets/icons/IconApple.svg" width=19 height=23 />)
            </div>
            <strong>{{statsTotalByOsDateCnt['iosAppTamperDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" color="danger" :value="statsTotalByOsDateCnt['iosAppTamperDetectCnt']" />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
              OS위변조(<img src="@/assets/icons/IconApple.svg" width=19 height=23 />)
            </div>
            <strong>{{statsTotalByOsDateCnt['iosOsTamperDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" :value="statsTotalByOsDateCnt['iosOsTamperDetectCnt']" />
          </CCol>
          <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
            <div style="color:#a2a2a2;fontWeight:bold;" class="stsLabel">
              디버깅(<img src="@/assets/icons/IconApple.svg" width=19 height=23 />)
            </div>
            <strong>{{statsTotalByOsDateCnt['iosDebugDetectCnt']}}건</strong>
            <CProgress class="progress-xs mt-2" :precision="1" :value="statsTotalByOsDateCnt['iosDebugDetectCnt']" color="light"/>
          </CCol>
        </CRow>
      </CCardFooter>
      <!-- OS별 총 건수 -->
    </CCard>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import printTools from '../js/common/printUtil.js'
import StatsTamperCount from './monitoring/dashboard/StatsTamperCount'
import StatsTamperLineChart from './monitoring/dashboard/StatsTamperLineChart'
import StatsTamperDoughnutChart from './monitoring/dashboard/StatsTamperDoughnutChart'
import { cilReload} from '@coreui/icons'
export default {
  name: 'Dashboard',
  mixins: [printTools],
  icons: { cilReload },
  components: {
    DatePicker,
    StatsTamperCount,
    StatsTamperLineChart,
    StatsTamperDoughnutChart,
  },
  data() {
    return {
      isModalOpen: false,
      selectAppList: [{value:null, label:"앱을 선택하세요."}],
      searchDate:[],
      componentKey: 0,
      selectedApp: null,
      selectedAppPackageNm: null,
      selectedAppVersion: null,
      selectedOsType: null,
      selectedStoreYn: null,
      clearBtn:false,      
      isRefresh: false,// 새로고침 on-off 여부
    }
  },
  computed: {
    appAllList(){
      return this.$store.state.appInfo.appAllList || []
    },
    appPackageNmList(){
      if(this.selectAppList.length == 1 && this.appAllList.length > 0){
        for(var i in this.appAllList){
          this.selectAppList.push({
            value : this.appAllList[i]['appKey'],
            label: this.appAllList[i]['appNm']+" - v"+this.$store.state.appInfo.appAllList[i]['appVersion']
          })
        }
      }
      return this.selectAppList
    },
    statsTotalByOsDateCnt(){
      return this.$store.state.statistics.statsTotalByDateCnt || []
    }
  },
  watch:{    
    isRefresh:{
      immediate: true,
      handler (val) {        
        // 새로고침 on-off 여부가 바뀌면 새로고침 event 를 제거하거나 다시 binding 해주기
        if (val) {
          this.$store.dispatch('dashboard/setRefreshEvent', window.setInterval(()=>{
            this.getStatsData()
          }, 60000))
        } else {
          window.clearInterval(this.$store.state.dashboard.refreshEvent );
        }
      }
    }
  },
  created(){
    //default date set
    var beforeDay = new Date()
    beforeDay.setDate(beforeDay.getDate()-30)
    this.searchDate = [beforeDay, new Date()]         

    //default data set 
    this.setStatsDateData()
    this.setStatsAppData()
  },
  mounted(){
    this.getAppAllList()
    this.getStatsData()
  },
  methods: {
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    changeDate(){
      this.clearBtn = true
      this.forceRerender()
      this.setStatsDateData()
      this.getStatsData()
    },
    changeApp(){
      this.clearBtn = true
      this.forceRerender()
      if(this.selectedApp == null){ //reset
        this.selectedAppPackageNm = null
        this.selectedAppVersion = null
        this.selectedOsType = null
        this.selectedStoreYn = null
        
        this.setStatsAppData()
      }else{
         for(var i in this.appAllList){
           if(this.appAllList[i]['appKey'] == this.selectedApp){
              this.selectedAppPackageNm = this.appAllList[i]['packageNm']
              this.selectedAppVersion = this.appAllList[i]['appVersion']
              this.selectedOsType = this.appAllList[i]['osType']
              this.selectedStoreYn = this.appAllList[i]['storeYn']
              break
            }
          }
          this.setStatsAppData()
      }
      this.getStatsData()
    },
    //component reload 
    forceRerender(){
      this.componentKey +=1;
    },
    async getAppAllList(){
      try{
        await this.$store.dispatch('appInfo/getAppAllList')
      }catch(error){
        console.log(error)
      }
    },
    async getStatsData(){
      try{
       await this.$store.dispatch('statistics/getStatistics')
      }catch(error){
        console.log(error)
      }
    },
    clear(){
      this.clearBtn=false
      
      var beforeDay = new Date()
      beforeDay.setDate(beforeDay.getDate()-30)
      this.searchDate = [beforeDay, new Date()]     
      
      this.selectedApp=null
      this.selectedAppPackageNm = null
      this.selectedAppVersion = null
      this.selectedOsType = null
      this.selectedStoreYn = null
     
      this.setStatsDateData()
      this.setStatsAppData()
     
      this.getStatsData()
    },
    setStatsDateData(){
      this.$store.commit('statistics/setStatsStartDate', this.$date(this.searchDate[0]).format('YYYY-MM-DD')) 
      this.$store.commit('statistics/setStatsEndDate', this.$date(this.searchDate[1]).format('YYYY-MM-DD'))
    },
    setStatsAppData(){
      this.$store.commit('statistics/setStatsAppKey', this.selectedApp)
      this.$store.commit('statistics/setStatsPackageNm', this.selectedAppPackageNm)
      this.$store.commit('statistics/setStatsAppVersion', this.selectedAppVersion)
      this.$store.commit('statistics/setStatsOsType', this.selectedOsType)
      this.$store.commit('statistics/setStatsStoreYn', this.selectedStoreYn)
    },
   
  }
};
</script>