<template>
  <div>
  <CRow>
    <StatsTamperModal v-if="isModalOpen" @close-modal="isModalOpen=false" :statsResultType="statsResultType" 
        :startDate="startDate" :endDate="endDate" :appKey=appKey :packageNm="packageNm" :appVersion="appVersion" :osType="osType" :storeYn="storeYn"/>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="primary" header="총 접속" :text="statsTotalCnt.connectCnt+' 건'">
        <template #default>
             <CButton
                  type="submit"
                  size="sm"
                  variant="ghost"
                  class="float-right btn-primary btn-lft-margin"
                  @click="clickDetailBtn('0')"
              >
                  <CIcon name="cil-magnifying-glass"/>
              </CButton>    

        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="statsMonthlyConnectCntList"
            point-hover-background-color="primary"            
            label="총접속건수"
            :labels="statsMonthlyLabelList"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" header="정상접속" :text="statsTotalCnt.normalCnt+' 건'">
        <template #default>
           <CButton
                  type="submit"
                  size="sm"
                  variant="ghost"
                  class="float-right btn-info btn-lft-margin"
                  @click="clickDetailBtn('1')"
              >
                  <CIcon name="cil-magnifying-glass"/>
              </CButton>  
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="statsMonthlyNormalCntList"
            point-hover-background-color="info"
            label="정상접속 건수"
            :labels="statsMonthlyLabelList"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="warning" header="이상탐지" :text="statsTotalCnt.tamperDetectCnt+' 건'"
      >
        <template #default>
           <CButton
                  type="submit"
                  size="sm"
                  variant="ghost"
                  class="float-right btn-warning btn-lft-margin"
                  @click="clickDetailBtn('2')"
              >
                  <CIcon name="cil-magnifying-glass"/>
              </CButton>  
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="statsMonthlyTamperDetectCntList"
            point-hover-background-color="warning"
            label="이상탐지 건수"
            :labels="statsMonthlyLabelList"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="danger" header="검증실패" :text="statsTotalCnt.failCnt+' 건'"
      >
        <template #default>
           <CButton
                  type="submit"
                  size="sm"
                  variant="ghost"
                  class="float-right btn-lft-margin"
                  @click="clickDetailBtn('3')"
              >
                  <CIcon name="cil-magnifying-glass"/>
              </CButton>  
        </template>
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="statsMonthlyFailCntList"
            point-hover-background-color="rgb(250,152,152)"
            label="검증실패 건수"
            :labels="statsMonthlyLabelList"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
  </CRow>
  </div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from './index.js'
import StatsTamperModal from './StatsTamperModal'
export default {
  name: 'StatsTamperCount',
  components: { 
    CChartLineSimple, 
    CChartBarSimple,
    StatsTamperModal
  },
  computed:{
    startDate(){
      return this.$store.state.statistics.statsStartDate
    },
    endDate(){
      return this.$store.state.statistics.statsEndDate
    },
    appKey(){
      return this.$store.state.statistics.statsAppKey
    },
    packageNm(){
      return this.$store.state.statistics.statsPackageNm
    },
    appVersion(){
      return this.$store.state.statistics.statsAppVersion
    },
    osType(){
      return this.$store.state.statistics.statsOsType
    },
    storeYn(){
      return this.$store.state.statistics.statsStoreYn
    },
    statsTotalCnt () {
      return this.$store.state.statistics.statsTotalCnt
    },   
    statsMonthlyConnectCntList(){
      return this.$store.state.statistics.statsMonthlyConnectCntList
    },
    statsMonthlyNormalCntList(){
      return this.$store.state.statistics.statsMonthlyNormalCntList
    },
    statsMonthlyTamperDetectCntList(){
      return this.$store.state.statistics.statsMonthlyTamperDetectCntList
    },
    statsMonthlyFailCntList(){
      return this.$store.state.statistics.statsMonthlyFailCntList
    },

    statsMonthlyLabelList(){
      return this.$store.state.statistics.statsMonthlyLabelList
    }
  },
  data(){
    return {
      isModalOpen: false,
      statsResultType: 0,
    }
  },  
  methods: {
    clickDetailBtn(type){
      this.statsResultType=type
      this.isModalOpen=true
    }
  }
}
</script>