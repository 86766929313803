<template>
<div>
    <StatsTamperModal v-if="isModalOpen" @close-modal="isModalOpen=false" :statsResultType="0" 
        :startDate="startDate" :endDate="endDate" :appKey=appKey :packageNm="packageNm" :appVersion="appVersion" :osType="osType" :storeYn="storeYn"/>
    <CButton
            type="submit"
            size="sm"
            variant="ghost"
            class="float-right btn-primary btn-lft-margin"
            @click="isModalOpen=true"
        >
            <CIcon name="cil-magnifying-glass"/>
        </CButton>
        <CChartDoughnut
        style="margin-top:50px;"
        :datasets="defaultDatasets"
        :options="defaultOptions"
        :labels="[ '정상', '이상', '실패']" 
        />
</div>
</template>
<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
import StatsTamperModal from './StatsTamperModal'

export default {
    name: 'ValidationDoughnutChart',
    components: {
        StatsTamperModal,
        CChartDoughnut  
    },
    computed:{
        startDate(){
            return this.$store.state.statistics.statsStartDate
        },
        endDate(){
            return this.$store.state.statistics.statsEndDate
        },
        appKey(){
            return this.$store.state.statistics.statsAppKey
        },
        packageNm(){
            return this.$store.state.statistics.statsPackageNm
        },
        appVersion(){
            return this.$store.state.statistics.statsAppVersion
        },
        osType(){
            return this.$store.state.statistics.statsOsType
        },
        storeYn(){
            return this.$store.state.statistics.statsStoreYn
        },
        statsTotalCnt(){
            return this.$store.state.statistics.statsTotalCnt
        },        
        defaultDatasets () {
            return [
                {
                borderWidth: 1,
                borderColor: [
                    '#4999ea',
                    '#e0a82c',
                    '#d16766',
                ],

                backgroundColor: [
                    '#4999ea',
                    '#e0a82c',
                    '#d16766',
                ],
                data: [this.statsTotalCnt.normalCnt, this.statsTotalCnt.tamperDetectCnt, this.statsTotalCnt.failCnt]
                }
            ]
        },
        defaultOptions(){
            return {
                legend:{
                    labels:{
                        usePointStyle:true,
                        fontSize: 17,
                        boxWidth: 40,
                        fontStyle: 'Bold',
                        fontColor:'#a2a2a2'
                    }
                }
            }
        }
    },
    data(){
        return {
            isModalOpen: false,
        }
    }
}
</script>
